import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageKeys } from '../../types/local-storage-keys.enum';
import { CommonModule } from '@angular/common';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { AuthService } from '../../services/auth.service';
import { User } from '../../types/user.model';

@Component({
  selector: 'korviu-logo',
  standalone: true,
  imports: [CommonModule, NzIconModule],
  templateUrl: './korviu-logo.component.html',
  styleUrls: ['./korviu-logo.component.scss']
})
export class KorviuLogoComponent {
  @Input() size: 'xs' | 's' | 'm' | 'l' = 's';
  @Input() theme: 'green' | 'white' = 'white';
  public get user(): User {
    return this.authService.currentUser;
  }
  constructor(
    private router: Router,
    public authService: AuthService
  ) {}

  public onLogoClick(): void {
    const path: string = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
    this.router.navigate([path ? '/workspace/dashboard/' : '/welcoming']);
  }
}
