import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { AuthService } from '../../services/auth.service';
import { User } from '../../types/user.model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'unigana-footer',
  standalone: true,
  imports: [TranslateModule, NzIconModule, CommonModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public get user(): User {
    return this.authService.currentUser;
  }
  constructor(
    private _router: Router,
    public authService: AuthService
  ) {}

  public onLinkClick(event: MouseEvent, link: string): void {
    event.preventDefault();
    event.stopPropagation();
    const fullUrl = this._router.serializeUrl(this._router.createUrlTree([link]));
    window.open(fullUrl, '_blank');
  }
}
