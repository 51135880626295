import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lang-translate-bar',
  standalone: true,
  imports: [TranslateModule, CommonModule],
  templateUrl: './lang-translate-bar.component.html',
  styleUrl: './lang-translate-bar.component.scss'
})
export class LangTranslateBarComponent {
  constructor(public translateService: TranslateService) {}

  public useLanguage(language: string): void {
    this.translateService.use(language);
  }
}
